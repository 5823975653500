import React from "react";
//import { colors } from "react-select/dist/declarations/src/theme";
import {io} from "socket.io-client";
//import { Config } from "src/helpers/context/config";

//let socketInitialize = null;


// socketInitialize = socketio("http://192.168.29.180:3001", {
//   jsonp: false,
//   path: "/",
//   upgrade: false,
//   reconnection: true,
// });

export const socket = io("https://vcall.makemyapps.co:3001/");

// export const socket = socketInitialize.connect((w)=>{
//   console.log("socket ::::::::::", w);

// });
socket.emit("PING",{})

socket.on("PONG", (data) => {
 
  // socket.emit("PING", () => {
 
  // })
  
})




// socket.emit("req",{en:"AdminSConfig",data:{}})
// console.log("ssssssssssssssssssssssssssss")



export const SocketContext = React.createContext();