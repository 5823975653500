import React, { useState, useEffect, Fragment, useRef } from "react";
// import user from "src/assets/images/profile/user-1.jpg";
// import chamet from "src/assets/images/apps/Chamet.png";
// import Dropdown from 'react-bootstrap/Dropdown';
import akaramThemeSound from "src/assets/sound/audiochat.mp3"; // Adjust the path to your audio file
import PreLoader from "src/components/Loader";
import LeftSideBar from "../../UI/leftsideBar";
import { io } from "socket.io-client";
import { Config } from "src/helpers/context";
import { URLS } from "src/helpers/constants/urls";
import axiosInstance from "src/helpers/axios/axiosInstance";
import Cookies from "js-cookie";

export const socket = io("https://vcall.makemyapps.co:3001/");

const ChatPage = () => {
  const [loader, setLoader] = useState(false);
  const [playSound, setPlaySound] = useState(false);
  const [msgList, setMsgList] = useState([]);
  const [filterMsgList, setFilterMsgList] = useState([]);
  const [realuser, setRealuser] = useState([]);
  const [fakeprofile, setFakeprofile] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isChatVisible, setChatVisible] = useState(false);
  const [openChatUser, setOpenChatUser] = useState("");
  const [currentChat, setCurrentChat] = useState([]);
  const [isPaid, setIsPaid] = useState(null);
  // const [save, setSave] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const chatContainerRef = useRef(null);

  // console.log("fakeprofile", fakeprofile)

  console.log(
    "isChatVisible :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::",
    isChatVisible
  );

  const handleInputSentMessege = (event) => {
    setInputValue(event.target.value);
  };

  const handleSentButtonClick = (realuid, adminuserid) => {
    // aa patiyu button click event par j call thavi jove
    if (inputValue.length > 0) {
      setInputValue("");
      socket.emit("req", {
        en: "PC",
        data: {
          uid: adminuserid,
          r: realuid,
          body: inputValue,
          type: "txt",
        },
      });

      currentChat.push({
        s: adminuserid,
        r: realuid,
        body: inputValue,
        type: "txt",
      });
    }

    console.log("\nn\n Imgggg", selectedImg);
    
    if (selectedImg && selectedImg != "") {
      setSelectedImg("");
      socket?.emit("req", {
        en: "PC",
        data: {
          uid: adminuserid,
          r: realuid,
          body: selectedImg,
          type: "image",
        },
      });

      currentChat.push({
        s: adminuserid,
        r: realuid,
        body: selectedImg,
        type: "image",
      });
    }
    // setCurrentChat(currentChat)
  };

  const toggleChat = async (cov_id, uid, rid, item) => {
    setOpenChatUser(cov_id);
    console.log(
      "toggleChat :::::::::::::::::::::::::::::::::::::::::::::::::::::::::: ",
      cov_id
    );

    console.log(
      "toggleChat :::::::::::::::::::::::::::::::::::::::::::::::::::::::::: ",
      openChatUser
    );

    Cookies.set("myCookie", cov_id, { expires: 7 }); // Expires in 7 days

    socket.emit("req", { en: "GMAMCL", data: { conId: cov_id, uid: uid } });
  };

  const getdatedifference = (currentDate, MessageDate) => {
    //console.log("MessageDate  ", MessageDate);
    //console.log("currentDate  ", currentDate);

    const d1 = new Date(MessageDate);
    const d2 = new Date(currentDate);

    const diffMs = d2 - d1;

    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (diffDays > 0) {
      return diffDays + " " + "Days";
    }

    const hoursDifference = Math.floor(diffMs / (1000 * 60 * 60));

    if (hoursDifference > 0) {
      return hoursDifference + " " + "Hours";
    }

    const minutes = Math.floor(diffMs / (1000 * 60));

    if (minutes > 0) {
      return minutes + " " + "Minutes";
    }

    const seconds = Math.floor(diffMs / 1000);

    if (seconds > 0) {
      return seconds + " " + "Seconds";
    }

    return "0 Seconds";
  };

  const handleStickerUpload = async (e) => {
    try {
      setLoader(true);
      const key = e.target.files[0];
      let formData = new FormData();
      formData.append("Chat_img", key);
      console.log("ADD_STICKER", key);
      const { status, data } = await axiosInstance.post(
        URLS.CHAT_IMAGE,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the appropriate content type header
          },
        }
      );

      if (status === 200) {
        if (!data?.flag) {
          console.log("ADD_STICKER", data);
          setSelectedImg(data?.path);
          // setLoader(false);
        } else {
          // setApiMessage("error", data?.error?.[0]?.msg);
        }
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    socket.emit("req", { en: "AdminSConfig", data: {} });
    socket.emit("req", { en: "GACL", data: {} });

    socket.on("res", (data) => {
      //GEt ALL Chat List
      console.log(
        "RES :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::",
        data
      );
      setFilterMsgList(data?.data?.msgdata);

      if (data?.en == "GACL") {
        setMsgList(data?.data?.msgdata);
      } else if (data?.en == "GMAM") {
        // Get My All Message

        setRealuser(data?.data?.realuser);

        setFakeprofile(data?.data?.adminuser);

        console.log(
          "::::::::::::::::::::::::::::::data?.data?.msgdata?.msg ",
          data?.data?.msgdata?.msg
        );

        setCurrentChat(data?.data?.msgdata?.msg);
        setChatVisible(true);
        console.log("isChatVisible :: SET ", isChatVisible);

        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop =
            chatContainerRef.current.scrollHeight;
        }
      } else if (data?.en == "PC") {
        console.log("openChatUser?._id ", openChatUser);

        console.log("openChatUser?._id ", openChatUser);
        console.log("data?.data?.covid ", data?.data?.covid);

        const myCookieValue = Cookies.get("myCookie");

        console.log("myCookieValue  ", myCookieValue);
        if (myCookieValue === data?.data?.covid) {
          console.log(
            "YESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS"
          );

          // currentChat.push(data.data)
          // newChatData.push(data.data)
          const updatedChat = [...currentChat, data.data];
          setCurrentChat(updatedChat);
          setPlaySound(true);
          if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
              chatContainerRef.current.scrollHeight;
          }
          // currentChat.push({
          //   "s": data?.data?.s,
          //   "r": openChatUser?.adminuserdata?.[0]?._id,
          //   "body": data?.data?.body,
          //   cd: new Date(),
          //   iur: '1'
          // })
          // setCurrentChat(newChatData)
          // if (chatContainerRef.current) {
          //   chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
          // }
        } else {
          console.log(
            "KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK"
          );

          socket.emit("req", { en: "GACL", data: {} });
          setPlaySound(true);
        }
      }
    });
    console.log("realuserrealuser",realuser);
  }, [socket, currentChat, playSound]);

  useEffect(() => {
    // Wait for the DOM content to be loaded
    document.addEventListener("DOMContentLoaded", () => {
      // Trigger sound playback when the DOM is ready
      setPlaySound(true);
    });

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("DOMContentLoaded", () => {});
    };
  }, []);

  useEffect(() => {
    if (playSound) {
      const audio = new Audio(akaramThemeSound);
      audio.currentTime = 0; // Reset the audio to the beginning
      audio.play().catch((error) => {
        // Handle any play() errors here
        console.error("Error playing audio:", error);
      });

      // After the sound finishes playing 
      setTimeout(() => {
        setPlaySound(false);
      }, 3000); 
    }
  }, [playSound]);

  //console.log("isPaid", msgList, isPaid);
  // console.log(Config.baseURL + selectedImg);
  return (
    <Fragment>
      {loader && <PreLoader />}
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <LeftSideBar />

        <div className="body-wrapper">
          <div
            className="container-fluid"
            style={loader ? { opacity: "0.3" } : {}}
          >
            <div className="card overflow-hidden chat-application">
              <div className="d-flex">
                <div className="w-50 d-none d-lg-block border-end user-chat-box">
                  <div className="px-2 pt-2 pb-6 d-flex justify-content-between w-100">
                    <div className="filter">
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" role="presentation">
                          <a
                            className={`nav-link ${
                              isPaid === null ? "active" : ""
                            }`}
                            data-bs-toggle="tab"
                            href="#all"
                            role="tab"
                            aria-selected={isPaid === null ? "true" : "false"}
                            onClick={() => setIsPaid(null)} // Reset isPaid when "All" tab is clicked
                          >
                            <span>All</span>
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          role="presentation"
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsPaid("1")}
                        >
                          <a
                            className={`nav-link ${
                              isPaid === "1" ? "active" : ""
                            }`}
                            data-bs-toggle="tab"
                            role="tab"
                            aria-selected={isPaid === "1" ? "true" : "false"}
                          >
                            <span>Paid</span>
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          role="presentation"
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsPaid("0")}
                        >
                          <a
                            className={`nav-link ${
                              isPaid === "0" ? "active" : ""
                            }`}
                            data-bs-toggle="tab"
                            role="tab"
                            aria-selected={isPaid === "0" ? "true" : "false"}
                          >
                            <span>Free</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div>
                      {/* <Dropdown className="my-auto me-4">
                        <Dropdown.Toggle  onChange={(e)=>{console.log(e.target.value);}}>
                          All Chats
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">Read</Dropdown.Item>
                          <Dropdown.Item href="#/action-3">Unread</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}

                      <select
                        className="form-select"
                        aria-label="Disabled select example"
                        onChange={(e) => {
                          const response = filterMsgList.filter((item) => {
                            switch (e.target.value) {
                              case "all":
                                // setSave(preSaved => !preSaved)
                                return item;
                              case "read":
                                return item.newmsg_unread < 1;

                              case "unread":
                                return item.newmsg_unread >= 1;
                              default:
                                break;
                            }
                          });
                          console.log("Filtered Records:", response);
                          setMsgList(response);
                        }}
                      >
                        <option value={"all"}>All Message</option>
                        <option value={"read"}>read</option>
                        <option value={"unread"}>Unread</option>
                      </select>

                      {/* 
                      {msgList.length === 0 && (
                        <div>
                          <h5 className="card-title fw-semibold my-3">
                            No Conversation
                          </h5>
                        </div>
                      )} */}

                      {/* <select
                        onChange={(e) => {
                          const response = msgList.filter((item) => (
                            e.target.value === "all"
                              ? true
                              : e.target.value === "read"
                                ? item.newmsg_unread <= 1
                                : e.target.value === "unread"
                                  ? item.newmsg_unread >= 1
                                  : false
                          ));
                          setMsgList(response);
                        }}
                        className="form-select"
                        aria-label="Select filter"
                      >
                        <option value={"all"}>All Message</option>
                        <option value={"read"}>Read</option>
                        <option value={"unread"}>Unread</option>
                      </select> */}
                    </div>
                  </div>
                  <div
                    className="app-chat"
                    style={{ height: "80vh", overflow: "auto" }}
                  >
                    <ul className="chat-users" data-simplebar="init">
                      <div
                        className="simplebar-wrapper"
                        style={{ margin: "0px" }}
                      >
                        <div className="simplebar-height-auto-observer-wrapper">
                          <div className="simplebar-height-auto-observer"></div>
                        </div>
                        <div className="simplebar-mask">
                          <div
                            className="simplebar-offset"
                            style={{ right: "0px", bottom: "0px" }}
                          >
                            <div
                              className="simplebar-content-wrapper"
                              tabIndex="0"
                              role="region"
                              aria-label="scrollable content"
                              //   style={{ height: "auto", overflow: "scroll" }}
                              style={{ height: "auto", overflow: "hidden" }}
                            >
                              <div
                                className="simplebar-content"
                                style={{ padding: "0px" }}
                              >
                                {msgList
                                  ?.filter(
                                    (obj) =>
                                      isPaid === null ||
                                      obj?.realuserdata?.[0]?.IsPaid ===
                                        Number(isPaid)
                                  )
                                  .map((item) => {
                                    const timeDifference = getdatedifference(
                                      new Date(),
                                      new Date(item.lastdate)
                                    );
                                    //console.log("item.item:::::::::::: ", item);

                                    return (
                                      <li
                                        id={item._id}
                                        onClick={(e) => {
                                          const clickedElement = e.target;

                                          // Remove the "active_chat" className from all elements
                                          const allElements =
                                            document.querySelectorAll(
                                              ".active_chat"
                                            );
                                          allElements.forEach((element) => {
                                            element.classList.remove(
                                              "active_chat"
                                            );
                                          });

                                          // Add the "active_chat" className to the clicked element
                                          clickedElement.classList.add(
                                            "active_chat"
                                          );
                                          toggleChat(
                                            item?.cov_id,
                                            item?.adminuserdata[0]?._id,
                                            item?.realuserdata[0]?._id,
                                            item
                                          );
                                          console.log(item);
                                        }}
                                      >
                                        <a
                                          className="px-4 py-3 bg-hover-light-black d-flex align-items-start justify-content-between chat-user bg-light"
                                          data-user-id="1"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <div
                                            className="d-flex align-items-center"
                                            onClick={(e) => {
                                              // Prevent the click event from propagating to the outer <li> element
                                              e.stopPropagation();
                                            }}
                                          >
                                            <img
                                              src={
                                                item?.realuserdata[0]
                                                  ?.Profile_Pic
                                              }
                                              alt="user1"
                                              width="48"
                                              height="48"
                                              className="rounded-circle"
                                            />
                                            <div className="ms-3 d-inline-block w-75">
                                              <h6
                                                className="mb-1 fw-semibold chat-title"
                                                data-username="James Anderson"
                                              >
                                                {
                                                  item?.realuserdata[0]
                                                    ?.UserName
                                                }
                                                {item?.realuserdata[0]
                                                  ?.IsPaid == 1 ? (
                                                  <span
                                                    className="mb-1 badge rounded-pill bg-primary"
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    Paid
                                                  </span>
                                                ) : (
                                                  <span
                                                    className="mb-1 rounded-pill badge bg-warning"
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    Free
                                                  </span>
                                                )}
                                              </h6>
                                              {item?.newmsg_unread >= 1 &&
                                              item?.last_msg_sender !=
                                                item?.adminuserdata[0]?._id ? (
                                                <span className=" w-200 fs-3 text-truncate text-body-color d-block">
                                                  {item.last_msg_type == "txt"
                                                    ? item?.last_msg_body
                                                    : item.last_msg_type ==
                                                      "image"
                                                    ? "Sent A Image"
                                                    : "Sent A Sticker"}
                                                </span>
                                              ) : (
                                                <span className="w-200 fs-3 text-truncate text-dark d-block">
                                                  {item.last_msg_type == "txt"
                                                    ? item?.last_msg_body
                                                    : item.last_msg_type ==
                                                      "image"
                                                    ? "Sent A Image"
                                                    : "Sent A Sticker"}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                          <span
                                            onClick={(e) => {
                                              // Prevent the click event from propagating to the outer <li> element
                                              e.stopPropagation();
                                            }}
                                          >
                                            {item?.newmsg_unread >= 1 &&
                                            item?.last_msg_sender !=
                                              item?.adminuserdata[0]?._id ? (
                                              <p className="fs-2 mb-0 text-white mb-1 badge rounded-pill bg-secondary">
                                                {timeDifference}
                                              </p>
                                            ) : (
                                              <p className="fs-2 mb-0 text-muted">
                                                {timeDifference}
                                              </p>
                                            )}
                                          </span>
                                        </a>
                                      </li>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="simplebar-placeholder"
                          style={{ width: "auto", height: "571px" }}
                        ></div>
                      </div>
                      <div
                        className="simplebar-track simplebar-horizontal"
                        style={{ visibility: "visible" }}
                      >
                        <div
                          className="simplebar-scrollbar"
                          style={{
                            width: "266px",
                            transform: "translate(-50%, -50%)",
                            display: "block",
                          }}
                        ></div>
                      </div>
                      <div
                        className="simplebar-track simplebar-vertical"
                        style={{ visibility: "visible" }}
                      >
                        <div
                          className="simplebar-scrollbar"
                          style={{
                            height: "25px",
                            transform: "translate(-50%, -50%)",
                            display: "block",
                          }}
                        ></div>
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="w-50 w-xs-100 chat-container">
                  <div className="chat-box-inner-part h-100">
                    <div className="chat-not-selected h-100 d-none">
                      <div className="d-flex align-items-center justify-content-center h-100 p-5">
                        <div className="text-center">
                          <span className="text-primary">
                            <i className="ti ti-message-dots fs-10"></i>
                          </span>
                          <h6 className="mt-2">Open chat from the list</h6>
                        </div>
                      </div>
                    </div>
                    {isChatVisible && (
                      <div className="chatting-box d-block">
                        <div className="p-9 border-bottom chat-meta-user d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <span className="position-relative">
                              <img
                                src={realuser?.Profile_Pic}
                                alt="user1"
                                width="48"
                                height="48"
                                className="rounded-circle"
                              />
                            </span>
                            <div className="ms-3 d-inline-block w-75">
                              <div className="d-flex align-items-center mb-1">
                                <h6 className="mb-0 fw-semibold chat-title">
                                  {realuser?.UserName}
                                </h6>
                                <p className="fs-2 mb-0 text-white ms-1 badge rounded-pill bg-primary">
                                  {realuser?.Location}
                                </p>
                              </div>
                              <span className="fs-2 text-truncate text-body-color d-block">
                                {realuser?.UserEmailId}
                              </span>
                            </div>
                          </div>

                          <div className="p-9 chat-meta-user d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <span className="position-relative">
                                <img
                                  src={
                                    Config.baseURL +
                                    "/" +
                                    fakeprofile?.Profile_Pic
                                  }
                                  alt="user1"
                                  width="48"
                                  height="48"
                                  className="rounded-circle"
                                />
                              </span>
                              <div className="ms-3 d-inline-block w-75">
                                <div className="d-flex align-items-center mb-1">
                                  <h6 className="mb-0 fw-semibold chat-title">
                                    {fakeprofile?.UserName}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>

                          <span className="position-relative">
                            <img
                              src={Config.baseURL + "/" + realuser?.appimage}
                              alt={realuser?.appName}
                              width="48"
                              height="48"
                              className="rounded-circle"
                              data-bs-toggle="tooltip"
                              title={realuser?.appName}
                            />
                          </span>
                        </div>
                        <div className="position-relative overflow-hidden d-flex">
                          <div className="position-relative d-flex flex-grow-1 flex-column">
                            <div
                              className="chat-box p-9"
                              style={{ height: "70vh", overflow: "auto" }}
                              data-simplebar="init"
                              ref={chatContainerRef}
                            >
                              <div
                                className="simplebar-wrapper"
                                style={{ margin: "-20px" }}
                              >
                                <div className="simplebar-height-auto-observer-wrapper">
                                  <div className="simplebar-height-auto-observer"></div>
                                </div>
                                <div className="simplebar-mask">
                                  <div
                                    className="simplebar-offset"
                                    style={{ right: "0", bottom: "0" }}
                                  >
                                    <div
                                      className="simplebar-content-wrapper"
                                      tabIndex="0"
                                      role="region"
                                      aria-label="scrollable content"
                                      style={{
                                        height: "auto",
                                        overflow: "hidden-scroll",
                                      }}
                                    >
                                      <div
                                        className="simplebar-content"
                                        style={{ padding: "20px" }}
                                      >
                                        <div
                                          className="chat-list chat active-chat"
                                          data-user-id="1"
                                        >
                                          {currentChat &&
                                            currentChat?.map((item) => {
                                              return (
                                                <>
                                                  {item?.s ==
                                                  fakeprofile?._id ? (
                                                    <div className="hstack gap-3 align-items-start mb-7 justify-content-end">
                                                      <div className="text-end">
                                                        <h6 className="fs-2 text-muted">
                                                          {getdatedifference(
                                                            new Date(),
                                                            new Date(item.cd)
                                                          )}{" "}
                                                          ago
                                                        </h6>
                                                        <div className=" w-200 p-2 bg-light-info text-dark rounded-1 d-inline-block fs-3">
                                                          {item.type ==
                                                          "txt" ? (
                                                            item.body
                                                          ) : item.type ==
                                                              "image" ||
                                                            item.type ==
                                                              "sticker" ? (
                                                            <img
                                                              style={{
                                                                width: "80px",
                                                                height: "100px",
                                                                objectFit:
                                                                  "fill",
                                                              }}
                                                              src={
                                                                item.body
                                                                  ? Config.baseURL +
                                                                    "/" +
                                                                    item.body
                                                                  : ""
                                                              }
                                                              alt="img"
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="hstack gap-3 align-items-start mb-7 justify-content-start">
                                                      <img
                                                        src={
                                                          realuser?.Profile_Pic
                                                        }
                                                        alt="user8"
                                                        width="40"
                                                        height="40"
                                                        className="rounded-circle"
                                                      />
                                                      <div>
                                                        <h6 className="fs-2 text-muted">
                                                          {realuser?.UserName},{" "}
                                                          {getdatedifference(
                                                            new Date(),
                                                            new Date(item.cd)
                                                          )}{" "}
                                                          ago 
                                                        </h6>
                                                        <div className="p-2 bg-light rounded-1 d-inline-block text-dark fs-3">
                                                          {" "}
                                                          {item.type ==
                                                          "txt" ? (
                                                            item.body
                                                          ) : item.type ==
                                                              "image" ||
                                                            item.type ==
                                                              "sticker" ? (
                                                            <img
                                                              style={{
                                                                width: "80px",
                                                                height: "100px",
                                                                objectFit:
                                                                  "fill",
                                                              }}
                                                              src={
                                                                item.body
                                                                  ? Config.baseURL +
                                                                    "/" +
                                                                    item.body
                                                                  : ""
                                                              }
                                                              alt="img"
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="simplebar-placeholder"
                                    style={{ width: "auto" }}
                                    //   style={{height : "619px"}}
                                  ></div>
                                </div>
                                <div
                                  className="simplebar-track simplebar-horizontal"
                                  style={{ visibility: "hidden" }}
                                >
                                  <div
                                    className="simplebar-scrollbar"
                                    style={{ width: "0px", display: "none" }}
                                  ></div>
                                </div>
                                <div
                                  className="simplebar-track simplebar-vertical"
                                  style={{ visibility: "visible" }}
                                >
                                  <div
                                    className="simplebar-scrollbar"
                                    style={{
                                      height: "25px",
                                      transform: "translate(-50%, -50%)",
                                      display: "block",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="px-9 py-6 border-top chat-send-message-footer">
                              {fakeprofile?.Status === 1 ? ( // Check if fakeprofile?.status is 1
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center gap-2 w-100">
                                    <input
                                      type="text"
                                      className="form-control message-type-box text-muted border-0 p-2"
                                      placeholder="Type a Message"
                                      value={inputValue}
                                      onChange={(e) =>
                                        handleInputSentMessege(e)
                                      }
                                      onKeyDown={(e) =>
                                        e.key === "Enter"
                                          ? handleSentButtonClick(
                                              realuser?._id,
                                              fakeprofile?._id
                                            )
                                          : null
                                      }
                                    ></input>
                                    {selectedImg !== "" && (
                                      <img
                                        style={{
                                          width: "50px",
                                          objectFit: "revert-layer",
                                          height: "40px",
                                        }}
                                        src={Config.baseURL + `/${selectedImg}`}
                                      />
                                    )}
                                    <button
                                      className="btn btn-primary"
                                      onClick={() =>
                                        handleSentButtonClick(
                                          realuser?._id,
                                          fakeprofile?._id
                                        )
                                      }
                                    >
                                      Sent
                                    </button>

                                    <input
                                      hidden
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) => handleStickerUpload(e)}
                                      id="chat-img"
                                    ></input>
                                    <label htmlFor="chat-img">
                                      <i
                                        className="fa-regular fa-images"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </label>
                                  </div>
                                  <ul className="list-unstyledn mb-0 d-flex align-items-center">
                                    <li>
                                      <a className="text-dark px-2 fs-7 bg-hover-primary nav-icon-hover position-relative z-index-5 ">
                                        <i className="ti ti-photo-plus"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              ) : (
                                <div>Disabled content or message</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatPage;
